import { combineReducers } from "redux";

import sessionReducer from "./sessionReducer";
import masterReducer from "./masterReducer";
import supplierReducer from "./supplierReducer";
import invoiceReducer from "./invoiceReducer";
import automotiveReducer from "./automotiveReducer";
import settingsReducer from "./settingsReducer";

const rootReducer = combineReducers({
  session: sessionReducer,
  master: masterReducer,
  supplier: supplierReducer,
  invoice: invoiceReducer,
  automotive: automotiveReducer,
  settings: settingsReducer,
});

export default rootReducer;
