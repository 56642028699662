import React, { Suspense, useState, forwardRef } from "react";
import { renderRoutes } from "react-router-config";
import { Link as RouterLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { useKeycloak } from "@react-keycloak/web";
import randomString from "randomstring";
import {
  Backdrop,
  LinearProgress,
  Slide,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  DialogContent,
  Grid,
  MenuItem,
  CircularProgress,
  IconButton,
  ButtonGroup,
  Select,
  TextField as MiTextField,
  Icon,
} from "@material-ui/core";
import MaterialTable from "material-table";

import jsPDF from "jspdf";
import "jspdf-autotable";

import { NavBar, TopBar } from "./components";
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from "@material-ui/lab";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";

import FileCopyIcon from "@material-ui/icons/FileCopyOutlined";
import SaveIcon from "@material-ui/icons/Save";
import ReceiptIcon from "@material-ui/icons/Receipt";
import Clear from "@material-ui/icons/Clear";
import Search from "@material-ui/icons/Search";
import GetAppIcon from "@material-ui/icons/GetApp";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import * as serviceCaller from "../../service";
import * as actionCaller from "../../actions";

import TransitionsSnackbar from "../../views/snackBar/snackBar";

import CheckAuthorization from "../../utils/checkAuthorization";
import {
  INV_V2_ADMIN,
  INV_V2_CASELOG,
  INVOICE_AUTOMOTIVE,
  INVOICE_BOX,
  INVOICE_RANDOM,
} from "../../utils/values";

import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import BlockIcon from "@material-ui/icons/Block";
import { useForm, Controller } from "react-hook-form";

import InvoiceViewButton from "components/ViewInvoiceButton";
import DownloadViewButton from "components/DownloadInvoiceButton";
import InvoiceSearchTable from "./InvoiceSearchTable";
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const tableIcons = {
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowUpwardIcon {...props} ref={ref} />
  )),
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  topBar: {
    zIndex: 2,
    position: "relative",
  },
  container: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  navBar: {
    zIndex: 3,
    width: 210,
    minWidth: 210,
    flex: "0 0 auto",
  },
  content: {
    overflowY: "auto",
    flex: "1 1 auto",
  },
  sidebar: {
    // width: 80,
    maxWidth: 74,
    textAlign: "center",
    padding: 10,
    backgroundColor: "#000036",
    color: "#E6E6E6",
    fontFamily: "Roboto",
    borderRadius: "0px 10px 10px 0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  speedDial: {
    position: "absolute",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(3),
      right: theme.spacing(3),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(3),
      left: theme.spacing(3),
    },
  },
}));

const categoryAddSchema = Yup.object().shape({
  category: Yup.string().required("Required"),
});

const invoiceSearchSchema = Yup.object().shape({
  wufoo: Yup.string(),
  invoiceNo: Yup.string(),
});

const Dashboard = (props) => {
  const [keycloak, initialized] = useKeycloak();

  const { handleSubmit, errors, control } = useForm();

  const { route } = props;

  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [addCategory, setAddCategory] = React.useState(false);
  const [invoiceSearch, setInvoiceSearch] = React.useState(false);
  const [alert, setAlert] = React.useState(false);
  const [severity, setSeverity] = React.useState("success");
  const [message, setMessage] = React.useState("");
  const [downloadInvoice, setDownloadInvoice] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState({
    loadingBD: false,
    imagePreviewLoading: false,
    coaPreviewLoading: false,
    otherDocPreviewLoading: false,
    fdaPreviewLoading: false,
    selectedDocType: "",
    selectedAsin: "",
    otherDocPreview: "/preview",
    imagePreview: "/preview",
    coaPreview: "/preview",
    fdaPreview: "/preview",
  });

  let [supplierList, setSupplierList] = useState([]);

  const handleChange = (evt) => {
    setSupplierList([...supplierList, evt.target.values]);
  };

  const actions = [
    { icon: <FileCopyIcon color="secondary" />, name: "Invoice Search" },
    { icon: <SaveIcon color="secondary" />, name: "Add Category" },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  //  addCategoryForm
  const addCategoryForm = async (data) => {
    let res = await serviceCaller.addCategory(data);
    if (res.data.statusCode === 200) {
      setAlert(false);
      props.addCategory(res.data.data);
      setSeverity("success");
      setMessage(res.data.message);
      setAlert(true);

      // resetForm();
      // setSubmitting(false);
    } else {
      setAlert(false);
      setSeverity("error");
      setMessage(res.data.message);
      setAlert(true);
    }
    setAddCategory(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.sidebar}>
          <NavBar />
        </div>
        <main className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
            <SpeedDial
              ariaLabel="SpeedDial example"
              className={classes.speedDial}
              hidden={false}
              icon={<SpeedDialIcon />}
              onClose={handleClose}
              onOpen={handleOpen}
              open={open}
              direction="up"
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  onClick={() => {
                    if (action.name === "Invoice Search") {
                      setInvoiceSearch(true);
                    } else if (action.name === "Add Category") {
                      setAddCategory(true);
                    }
                  }}
                />
              ))}
            </SpeedDial>
            <Dialog
              maxWidth="xl"
              onClose={() => setInvoiceSearch(false)}
              open={invoiceSearch}
              TransitionComponent={Transition}
            >
              <DialogTitle>
                <Typography variant="h4" color="primary">
                  Invoice Search
                </Typography>
              </DialogTitle>
              <InvoiceSearchTable />
            </Dialog>
            <Dialog
              keepMounted
              onClose={() => setAddCategory(false)}
              open={addCategory}
              TransitionComponent={Transition}
            >
              <DialogTitle>
                <Typography variant="h4" color="primary">
                  Add Category
                </Typography>
              </DialogTitle>
              <form onSubmit={handleSubmit(addCategoryForm)}>
                <DialogContent>
                  <Grid
                    alignItems="center"
                    container
                    justify="center"
                    spacing={2}
                  >
                    <Grid item xs={12}>
                      <Controller
                        as={
                          <MiTextField
                            variant="outlined"
                            fullWidth
                            label="Add Category"
                            placeholder="Add Category"
                          />
                        }
                        name="categoryName"
                        control={control}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Controller
                        as={
                          <Select
                            multiple
                            fullWidth
                            label="Suppliers"
                            variant="outlined"
                          >
                            {props.suppliers.map((e) => (
                              <MenuItem key={e._id} value={e._id}>
                                {e.supplierName}
                              </MenuItem>
                            ))}
                          </Select>
                        }
                        name="supplierStack"
                        control={control}
                        defaultValue={[]}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    color="secondary"
                    onClick={() => setAddCategory(false)}
                  >
                    Cancel
                  </Button>
                  <Button color="primary" type="submit" variant="contained">
                    Submit
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
            {alert ? (
              <TransitionsSnackbar severity={severity} message={message} />
            ) : null}
          </Suspense>
        </main>
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  route: PropTypes.object,
};

const mapStateToProps = (state) => ({
  searchedInvoices: state.invoice.searchedInvoices,
  invoiceGenerator: state.supplier.invoiceGenerator,
  suppliers: state.supplier.suppliers,
});

const mapDispatchToProps = (dispatch) => {
  return {
    selectInvoice: (val) => dispatch(actionCaller.selectInvoice(val)),
    searchInvoices: (val) => dispatch(actionCaller.searchInvoices(val)),
    addCategory: (val) => dispatch(actionCaller.addCategory(val)),
    getInvoiceGenerator: (val) =>
      dispatch(actionCaller.getInvoiceGenerator(val)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
