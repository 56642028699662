import React, { useState, useEffect } from "react";
import { Button, CircularProgress, Typography } from "@material-ui/core";

import * as serviceCaller from "../../service";

function LinkButton({ wid, name, ...rest }) {
  let [loading, setLoading] = useState(false);
  let [error, setError] = useState(false);

  return (
    <Button
      variant="outlined"
      startIcon={loading ? <CircularProgress size={18} /> : <></>}
      onClick={async () => {
        setLoading(true);

        let automotive = await serviceCaller.getAutomotive(wid);
        if (automotive.status == 200) {
          let url = automotive.data.result.url;
          if (!/^https?:\/\//i.test(url)) {
            url = "http://" + url;
          }
          window.open(url, "_blank");
        } else {
          setError(true);
        }

        setLoading(false);
      }}
      disabled={loading || error}
      {...rest}
    >
      <Typography>{name}</Typography>
    </Button>
  );
}

export default LinkButton;
