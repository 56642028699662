const axios = require("axios");
const keycloak = require("../keycloak");
const baseURL = process.env.REACT_APP_BASEURL;

export function getAllFiles(query) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .get(
      `${baseURL}fileupload/all?pageNo=${query.pageNo}&pageSize=${
        query.pageSize
      }&asin=${query.asin ? query.asin : ""}`,
      {
        headers: headers,
      }
    )
    .then((res) => (res.data.statusCode == 200 ? res.data.data : []))
    .catch(function (error) {});
}

export function getFileDetail(fileId) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .get(`${baseURL}fileupload/${fileId}`, {
      headers: headers,
    })
    .then((res) => (res.data.statusCode == 200 ? res.data.data : []))
    .catch(function (error) {});
}

export function deleteFile(id) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .delete(`${baseURL}fileupload?id=${id}`, {
      headers: headers,
    })
    .then((res) => res)
    .catch(function (error) {});
}

export function updateFile(id, type = "ENABLE") {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .put(
      `${baseURL}fileupload?id=${id}&action=${type}`,
      {},
      {
        headers: headers,
      }
    )
    .then((res) => res)
    .catch(function (error) {});
}
