import React from "react";
import { Button, Grid, IconButton } from "@material-ui/core";
import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";
import InvoiceViewButton from "components/ViewInvoiceButton";
import DownloadViewButton from "components/DownloadInvoiceButton";
import DeleteInvoiceButton from "components/DeleteInvoiceButton";
import {
  INVOICE_AUTOMOTIVE,
  INVOICE_BOX,
  INVOICE_GENERAL,
  INVOICE_RANDOM,
} from "utils/values";
import CheckAuthorization from "../../utils/checkAuthorization";
import { INV_V2_ADMIN } from "../../utils/values";

function invoiceSearchColumn() {
  return [
    {
      Header: () => null,
      id: "expander",
      Cell: ({ row }) => (
        <IconButton size="small" {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      ),
      SubCell: () => null,
    },
    {
      Header: "Wufoo",
      accessor: "wufoo",
      style: { whiteSpace: "unset" },
      width: "10",
    },
    {
      Header: "Invoice No",
      accessor: "invoiceData.invoiceNo",
      style: { whiteSpace: "unset" },
    },
    {
      Header: "communication Email",
      accessor: "communicationEmail",
    },
    {
      Header: "Seller Email",
      accessor: "sellerEmail",
    },
    {
      Header: "Created On",
      accessor: "invoiceCreatedOn",
      style: { whiteSpace: "unset" },
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ value }) => {
        return Number(value)
          ? new Date(Number(value)).toLocaleDateString("en-us", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
          : "-";
      },
    },
    {
      Header: "Status",
      accessor: "invoiceStatus",
      style: { whiteSpace: "unset" },
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Type",
      accessor: "invoiceType",
      style: { whiteSpace: "unset" },
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ value }) => {
        switch (value) {
          case INVOICE_GENERAL:
            return "GEN";
          case INVOICE_AUTOMOTIVE:
            return "AUT";
          case INVOICE_RANDOM:
            return "RAN";
          case INVOICE_BOX:
            return "BOX";
          default:
            return "-";
        }
      },
    },
    {
      Header: "Actions",
      accessor: "",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row: { original } }) => {
        return (
          <Grid container justify="flex-start" spacing={1}>
            <Grid item>
              <InvoiceViewButton
                invoiceId={original._id}
                supplierId={original.supplier_id}
                marketPlaceId={original.marketPlaceId}
                size="small"
                color="primary"
              />
            </Grid>
            <Grid item>
              <DownloadViewButton
                invoiceId={original._id}
                supplierId={original.supplier_id}
                marketPlaceId={original.marketPlaceId}
                size="small"
                color="primary"
              />
            </Grid>
            <CheckAuthorization roles={[INV_V2_ADMIN]}>
              <Grid item>
                <DeleteInvoiceButton invoiceId={original._id} />
              </Grid>
            </CheckAuthorization>
          </Grid>
        );
      },
    },
  ];
}

export default invoiceSearchColumn;
