import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

import ViewDocsButton from "components/ViewDocsButton";
import AsinButton from "components/AsinButton";
import LinkButton from "components/LinkButton";
import AutomotiveButton from "components/AutomotiveButton";
import {
  PRIMARY,
  SECONDARY,
  INVOICE_AUTOMOTIVE,
  APPROVED,
} from "../../utils/values";
import { useForm, Controller } from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";
import { useSnackbar } from "notistack";
import * as serviceCaller from "../../service";
import * as actionCaller from "../../actions";
import { useSelector, useDispatch } from "react-redux";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import InvoiceItemActions from "./InvoiceItemActions";
import RollBackInvoiceItemAction from "./RollBackInvoiceItemAction";

function InvoiceItemSubRow({ rowData, data, isCaseLog = false }) {
  let [invoiceItemData, setInvoiceItemData] = useState(data);

  return (
    <>
      <Grid container alignItems="center" justify="space-between">
        <Grid item style={{ flex: 1 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item style={{ minWidth: "100px" }}>
              <Typography
                component="h5"
                variant="button"
                color={
                  invoiceItemData.type == PRIMARY ? "primary" : "secondary"
                }
              >
                {String(invoiceItemData.type).toUpperCase()}
              </Typography>
            </Grid>
            <Grid item style={{ minWidth: "200px" }}>
              <Typography component="h5" variant="button">
                {String(
                  invoiceItemData.masterproduct &&
                    invoiceItemData.masterproduct.category
                    ? invoiceItemData.masterproduct.category
                    : ""
                ).toUpperCase()}
              </Typography>
            </Grid>
            <Grid item>
              {rowData.invoiceType != INVOICE_AUTOMOTIVE ? (
                <AsinButton asin={invoiceItemData.asin} size="small" />
              ) : (
                <AutomotiveButton
                  name="Website"
                  wid={invoiceItemData.wid}
                  size="small"
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <Grid container spacing={2} alignItems="center" justify="flex-start">
            <Grid item>
              <ViewDocsButton
                rowData={rowData}
                fileIds={[
                  invoiceItemData.imageSet,
                  invoiceItemData.coaDoc,
                  invoiceItemData.fdaDoc,
                  invoiceItemData.otherDoc,
                ].filter((e) => e)}
                size="small"
              />
            </Grid>
            {isCaseLog ? (
              !invoiceItemData.result ? (
                <InvoiceItemActions
                  invoiceData={rowData}
                  invoiceItemData={invoiceItemData}
                  setInvoiceItemData={setInvoiceItemData}
                />
              ) : (
                <>
                  <Grid item>
                    <Typography
                      component="h5"
                      variant="button"
                      style={{
                        color:
                          invoiceItemData.result == APPROVED ? "green" : "red",
                      }}
                    >
                      {"Case Status - " + invoiceItemData.result}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <RollBackInvoiceItemAction
                      invoiceData={rowData}
                      invoiceItemData={invoiceItemData}
                      setInvoiceItemData={setInvoiceItemData}
                    />
                  </Grid>
                </>
              )
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default InvoiceItemSubRow;

/*
<Button variant="outlined" color="primary">
  Primary
</Button>
<Button variant="outlined" color="secondary">
  Secondary
</Button>

*/
