import React from "react";
import {
  AllInbox,
  BarChart,
  DashboardOutlined,
  DirectionsCar,
  FolderOutlined,
  PeopleOutlined,
  ReceiptOutlined,
  SettingsOutlined,
  TrendingUp,
  VerifiedUserOutlined,
  DashboardTwoTone,
} from "@material-ui/icons";

import {
  INV_V2_ADMIN,
  INV_V2_CASELOG,
  INV_V2_DOCUMENTATION,
  INV_V2_VALIDATION,
} from "../../../../utils/values";

export default [
  {
    title: "Pages",
    pages: [
      {
        title: "Master",
        href: "/master",
        access: [INV_V2_ADMIN, INV_V2_DOCUMENTATION, INV_V2_VALIDATION],
        Icon: DashboardOutlined,
      },
      {
        title: "Supplier",
        href: "/supplier",
        access: [INV_V2_ADMIN, INV_V2_DOCUMENTATION, INV_V2_VALIDATION],
        Icon: PeopleOutlined,
      },
      {
        title: "Supplier Dashboard",
        href: "/supplier-dashboard",
        access: [INV_V2_ADMIN, INV_V2_DOCUMENTATION, INV_V2_VALIDATION],
        Icon: DashboardTwoTone,
      },
      {
        title: "Automotive",
        href: "/automotive",
        access: [INV_V2_ADMIN, INV_V2_DOCUMENTATION, INV_V2_VALIDATION],
        Icon: DirectionsCar,
      },
      {
        title: "Other Invoice",
        href: "/otherinvoice",
        access: [INV_V2_ADMIN, INV_V2_DOCUMENTATION, INV_V2_VALIDATION],
        Icon: AllInbox,
      },
      {
        title: "Validation",
        href: "/validate",
        access: [INV_V2_ADMIN, INV_V2_VALIDATION],
        Icon: VerifiedUserOutlined,
      },
      {
        title: "Case Log",
        href: "/caselog",
        access: [
          INV_V2_ADMIN,
          INV_V2_CASELOG,
          INV_V2_DOCUMENTATION,
          INV_V2_VALIDATION,
        ],
        Icon: ReceiptOutlined,
      },
      {
        title: "Case Reports",
        href: "/reports",
        access: [INV_V2_ADMIN, INV_V2_CASELOG],
        Icon: BarChart,
      },
      {
        title: "Application Tracker",
        href: "/application",
        access: [INV_V2_ADMIN, INV_V2_CASELOG],
        Icon: TrendingUp,
      },
      {
        title: "Files",
        href: "/files",
        access: [INV_V2_ADMIN],
        Icon: FolderOutlined,
      },

      {
        title: "Settings",
        href: "/settings",
        access: [INV_V2_ADMIN],
        Icon: SettingsOutlined,
      },
    ],
  },
];
