import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import Slide from "@material-ui/core/Slide";

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

export default function TransitionsSnackbar({ severity, message }) {
  const [state, setState] = React.useState({
    open: true,
    Transition: SlideTransition
  });

  const handleClose = () => {
    setState({
      ...state,
      open: false
    });
  };

  return (
    <Snackbar
      open={state.open}
      autoHideDuration={5000}
      onClose={handleClose}
      TransitionComponent={state.Transition}
    >
      <Alert variant="filled" onClose={handleClose} severity={severity}>
        <pre>{message}</pre>
      </Alert>
    </Snackbar>
  );
}
