import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

const reactQuery = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={reactQuery}>
    <App />
    <ReactQueryDevtools position="top-right" initialIsOpen={false} />
  </QueryClientProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
