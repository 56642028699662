import React, { Fragment, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Drawer, Divider, Paper, Avatar, Typography } from "@material-ui/core";
import { Hidden } from "@material-ui/core";

import useRouter from "utils/useRouter";
import { Navigation } from "components";
import navigationConfig from "./navigationConfig";
import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import PeopleIcon from "@material-ui/icons/PeopleOutlined";
import ReceiptIcon from "@material-ui/icons/Receipt";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import BarChartIcon from "@material-ui/icons/BarChart";
import InputIcon from "@material-ui/icons/Input";
import FolderIcon from "@material-ui/icons/FolderOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";

import { useKeycloak } from "@react-keycloak/web";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    overflowY: "auto",
  },
  content: {
    padding: theme.spacing(2),
  },
  profile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    textAlign: "center",
    marginTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  navigation: {
    marginTop: theme.spacing(2),
  },
  logo: {
    width: 50,
    padding: 5,
  },
  navIcon: {
    color: "#E6E6E6",
    fontSize: 15,
  },
  navItems: {
    color: "#E6E6E6",
    paddingTop: 10,
    opacity: 0.5,
    transition: "0.2s ease-in-out",
    cursor: "pointer",
    "&:hover": {
      color: "#E6E6E6",
      opacity: 1,
    },
    "&:active": {
      color: "#E6E6E6",
      opacity: 1,
      transform: "scale(1.1)",
    },
  },
  navText: {
    fontSize: 11,
    color: "#E6E6E6",
  },
}));

const NavBar = (props) => {
  const { openMobile, onMobileClose, className, ...rest } = props;

  const classes = useStyles();
  const router = useRouter();

  const [keycloak, initialized] = useKeycloak();

  const isAllowed = (realmRoles, pageRoles) => {
    let matchingRoles = pageRoles.filter(function (e) {
      return realmRoles.indexOf(e) > -1;
    });
    return matchingRoles.length >= 1 ? true : false;
  };

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname]);

  return (
    <Fragment>
      <div>
        <RouterLink to="/">
          <img
            alt="Logo"
            className={classes.logo}
            src="/images/logos/TFG.png"
          />
        </RouterLink>
        {navigationConfig.map((list) => {
          // if(isAllowed(keycloak.realmAccess.roles, ))
          let allowedPages = list.pages.filter((e) =>
            isAllowed(keycloak.realmAccess.roles, e.access)
          );

          return allowedPages.map(({ title, href, Icon }) => (
            <RouterLink to={href}>
              <div className={classes.navItems}>
                <Icon className={classes.navIcon} />
                <p className={classes.navText}>{title}</p>
              </div>
            </RouterLink>
          ));
        })}
      </div>
      <div className={classes.navItems} onClick={() => keycloak.logout()}>
        <InputIcon
          className={classes.navIcon}
          onClick={() => keycloak.logout()}
        />
        <p className={classes.navText}>Logout</p>
      </div>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  loggedInUser: state.session.user,
});

export default connect(mapStateToProps)(NavBar);
