import { applyMiddleware, createStore, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
// import { createLogger } from 'redux-logger';

import rootReducer from "reducers";

// const loggerMiddleware = createLogger();

export default function configureStore(preloadedState = {}) {
  const persistConfig = {
    key: "root",
    storage: storageSession,
    whitelist: ["session", "master", "supplier", "invoice"]
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const middlewares = [thunkMiddleware]; // loggerMiddleware
  const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares)
  );

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers);

  const store = createStore(
    persistedReducer,
    preloadedState,
    composedEnhancers
  );
  const persistor = persistStore(store);

  return { store, persistor };
}
