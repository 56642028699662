import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

import { useForm, Controller } from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";
import { useSnackbar } from "notistack";
import * as serviceCaller from "../../service";
import {
  INVOICE_AUTOMOTIVE,
  INVOICE_RANDOM,
  INVOICE_BOX,
  INVOICE_GENERAL,
} from "../../utils/values";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

function RejectInvoiceItemAction({
  invoiceData,
  invoiceItemData,
  setInvoiceItemData,
}) {
  let [rejectedState, setRejectedState] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { handleSubmit, control } = useForm({
    defaultValues: {
      rejectedOn: new Date().getTime(),
    },
  });

  const rejectedProduct = (rejectedFormData) => {
    let newData = {
      ...rejectedFormData,
      rejectedOn: new Date(rejectedFormData.rejectedOn).getTime(),
      invoice_id: invoiceData._id,
      invoiceItemId: invoiceItemData._id,
      caseLogNo: invoiceItemData.caseLogNo,
    };

    if (invoiceData.invoiceType == INVOICE_GENERAL) {
      newData.master_id = invoiceItemData.masterproduct._id;
      newData.supplier_id = invoiceData.supplier_id;
      newData.marketPlace = invoiceData.marketPlaceId;
    }

    if (invoiceData.invoiceType == INVOICE_RANDOM) {
      newData.marketPlace = invoiceData.marketPlaceId;
    }

    if (invoiceItemData.coaDoc) {
      newData.reportType = "COA";
      newData.coaType = invoiceItemData.coaDoc;
    }

    serviceCaller.addRejectList(newData).then((res) => {
      if (res.status === 201) {
        enqueueSnackbar(res.data.message, {
          variant: "success",
        });
        setInvoiceItemData({
          ...invoiceItemData,
          result: res.data.result.invoiceItem.result,
        });
        setRejectedState(false);
      } else {
        enqueueSnackbar(res.data.message, {
          variant: "error",
        });
      }
    });
  };

  return (
    <>
      <Button
        onClick={() => setRejectedState(true)}
        variant="outlined"
        color="secondary"
        size="small"
      >
        Rejected
      </Button>
      <Dialog onClose={() => setRejectedState(false)} open={rejectedState}>
        <DialogTitle>{"PRODUCT REJECTED"}</DialogTitle>
        <form onSubmit={handleSubmit(rejectedProduct)}>
          <DialogContent>
            <Grid alignItems="center" container justify="center" spacing={2}>
              <Grid item xs={6}>
                <Controller
                  render={({ onChange, value }) => (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Rejected On"
                        value={value}
                        onChange={onChange}
                        format="MM/dd/yyyy"
                        fullWidth
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  )}
                  name="rejectedOn"
                  rules={{ required: true }}
                  control={control}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      label="Rejected By"
                      variant="outlined"
                      type="text"
                    />
                  }
                  name="rejectedBy"
                  rules={{ required: true }}
                  control={control}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  as={
                    <Select
                      fullWidth
                      label="Select Report Type"
                      variant="outlined"
                      defaultValue="NA"
                    >
                      <MenuItem value="COA">COA</MenuItem>
                      <MenuItem value="FDA">FDA</MenuItem>
                      <MenuItem value="GMP">GMP</MenuItem>
                      <MenuItem value="NA">Not Applicable</MenuItem>
                    </Select>
                  }
                  name="reportType"
                  control={control}
                />
              </Grid>
              {/* <Grid item xs={6}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      label="Case Log No"
                      variant="outlined"
                      type="text"
                    />
                  }
                  name="caseLogNo"
                  rules={{ required: true }}
                  control={control}
                />
              </Grid> */}
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      multiline
                      rows="4"
                      type="text"
                      variant="outlined"
                      label="Reason of Rejection"
                    />
                  }
                  name="reasonOfRejection"
                  rules={{ required: true }}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      multiline
                      rows="4"
                      type="text"
                      variant="outlined"
                      label="Remarks"
                    />
                  }
                  name="remarks"
                  rules={{ required: true }}
                  control={control}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setRejectedState(false)}>
              Cancel
            </Button>
            <Button color="primary" type="submit" variant="contained">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default RejectInvoiceItemAction;
