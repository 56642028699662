import * as actionTypes from "actions";

const initialState = {
  settings: {},
};

const automotiveReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SETTINGS: {
      return Object.assign({}, state, {
        settings: action.payload,
      });
    }

    default: {
      return state;
    }
  }
};

export default automotiveReducer;
