import * as actionTypes from "actions";

const initialState = {
  websiteList: [],
  availableWebsiteList: [],
};

const automotiveReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_AUTOMOTIVE_WEBSITES: {
      return Object.assign({}, state, {
        websiteList: action.payload,
      });
    }

    case actionTypes.GET_AVAILABLE_AUTOMOTIVE_WEBSITES: {
      return Object.assign({}, state, {
        availableWebsiteList: action.payload,
      });
    }

    default: {
      return state;
    }
  }
};

export default automotiveReducer;
