import React from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import MomentUtils from "@date-io/moment";
import { Provider as StoreProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { renderRoutes } from "react-router-config";

import { KeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";

import theme from "./theme";
import { configureStore } from "./store";
import routes from "./routes";
import { ScrollReset } from "./components";
import { SnackbarProvider } from "notistack";

import "./assets/scss/index.scss";

import { ConfirmProvider } from "material-ui-confirm";

const history = createBrowserHistory();
const { store, persistor } = configureStore();

const App = () => {
  return (
    <KeycloakProvider
      keycloak={keycloak}
      LoadingComponent={<p>Loading...</p>}
      onEvent={(event, error) => console.log("onKeycloakEvent", event, error)}
      onTokens={(tokenData) => {
        localStorage.setItem("inv_v2_token", tokenData?.token);
      }}
    >
      <StoreProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={1}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <ConfirmProvider>
                  <Router history={history}>
                    <ScrollReset />
                    {renderRoutes(routes)}
                  </Router>
                </ConfirmProvider>
              </MuiPickersUtilsProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </PersistGate>
      </StoreProvider>
    </KeycloakProvider>
  );
};

export default App;
