import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  useTable,
  useExpanded,
  usePagination,
  useSortBy,
  useAsyncDebounce,
  useFilters,
} from "react-table";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  TextField,
  Paper,
  LinearProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

import * as serviceCaller from "../../service";

import invoiceSearchColumn from "./InvoiceSearchColumns";
import InvoiceItemSubRow from "components/InvoiceItemSubRow";

function InvoiceSearchTable() {
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const [value, setValue] = React.useState(filterValue);
    const onChange = useAsyncDebounce((value) => {
      setFilter(value || undefined);
    }, 400);

    return (
      <TextField
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
    );
  }

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  function SubRows({ row, rowProps, visibleColumns, data, loading }) {
    if (loading) {
      return (
        <TableRow>
          <TableCell colSpan={visibleColumns.length}>Loading...</TableCell>
        </TableRow>
      );
    }

    // error handling here :)

    return (
      <>
        {data.map((item, i) => {
          return (
            <TableRow {...rowProps} key={`${rowProps.key}-expanded-${i}`}>
              <TableCell colSpan={visibleColumns.length}>
                <InvoiceItemSubRow rowData={row.original} data={item} />
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  }

  function SubRowAsync({ row, rowProps, visibleColumns }) {
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState([]);
    console.log("data",data)

    useEffect(() => {
      (async () => {
        let invoiceItems = await serviceCaller.getInvoiceItemsByInvoiceId(
          row.original._id
        );
        setData(invoiceItems.data.result);
        setLoading(false);
      })();
    }, []);

    return (
      <SubRows
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
        data={data}
        loading={loading}
      />
    );
  }

  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, visibleColumns }) => (
      <SubRowAsync
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
      />
    ),
    []
  );

  const loggedInUser = useSelector((state) => state.session.user);
  const queryClient = useQueryClient();

  const updateInvoice = useMutation(
    ({ invoiceId, invoiceData }) => {
      return serviceCaller.updateInvoiceStatus(invoiceId, invoiceData);
    },
    {
      onSuccess: (data, variables) => {
        refetch();
      },
    }
  );

  //table columns with memo to avoid re-rendering
  const columns = React.useMemo(
    () => invoiceSearchColumn(loggedInUser, updateInvoice),
    []
  );

  //table state
  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize, setQueryPageSize] = useState(10);
  const [queryPageSort, setQueryPageSort] = useState("-_id");
  const [queryPageFilter, setQueryPageFilter] = useState({});

  //fetching data using react query
  const { isLoading, isFetching, status, data, refetch } = useQuery(
    [
      "INVOICE-SEARCH",
      {
        page: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: queryPageSort,
        filter: queryPageFilter,
      },
    ],
    async ({ queryKey }) => {
      let { page, pageSize, sortBy, filter } = queryKey[1];
      return serviceCaller.getInvoices(
        {
          ...filter,
        },
        page + 1,
        pageSize,
        sortBy.split(",")
      );
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: 300000,
    }
  );

  let tableData = React.useMemo(
    () => (isLoading || status == "error" ? [] : data.data.result.docs),
    [data]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    visibleColumns,
    state: { pageIndex, pageSize, sortBy, filters },
  } = useTable(
    {
      columns,
      data: tableData,
      defaultColumn,
      manualFilters: true,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      pageCount:
        isLoading || status == "error" ? 0 : data.data.result.totalPages,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
    // useRowSelect
  );

  const classes = useStyles();

  useEffect(() => {
    if (pageIndex == queryPageIndex) {
      gotoPage(0);
    }
    setQueryPageIndex(pageIndex);
    setQueryPageSize(pageSize);
    setQueryPageSort(
      sortBy[0]
        ? sortBy[0].desc
          ? "-" + sortBy[0].id
          : "" + sortBy[0].id
        : "-_id"
    );
    let filterObj = {};
    filters.map((e) => {
      filterObj[e.id] = e.value;
    });
    setQueryPageFilter(filterObj);
  }, [pageIndex, pageSize, sortBy, filters]);

  return (
    <>
      <Paper variant="outlined" className={classes.root}>
        <TableContainer className={classes.container}>
          <Table
            {...getTableProps()}
            stickyHeader
            size="small"
            style={{ position: "relative" }}
          >
            <TableHead className={classes.tableHead}>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <TableCell
                        align={column.textAlign || "left"}
                        {...column.getHeaderProps()}
                      >
                        <div {...column.getSortByToggleProps()}>
                          {column.render("Header")}
                          <TableSortLabel
                            active={column.isSorted}
                            style={{
                              display: column.isSorted ? "" : "none",
                            }}
                            direction={
                              column.isSorted && column.isSortedDesc
                                ? "desc"
                                : "asc"
                            }
                          />
                        </div>
                        <div style={{ marginTop: "5px" }}>
                          {column.canFilter ? column.render("Filter") : null}
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableHead>
            {isLoading || isFetching ? (
              <LinearProgress style={{ width: "100%", position: "absolute" }} />
            ) : (
              <></>
            )}
            <TableBody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                const rowProps = row.getRowProps();
                return (
                  <>
                    <TableRow key={`table-row-${i}`} {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <TableCell
                            align={cell.column.textAlign || "left"}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    {row.isExpanded &&
                      renderRowSubComponent({ row, rowProps, visibleColumns })}
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid
          container
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
          justify="flex-end"
          alignItems="center"
        >
          <Grid item>
            <TablePagination
              count={pageCount * pageSize}
              page={pageIndex}
              onChangePage={async (evt, newPage) => {
                gotoPage(newPage);
              }}
              rowsPerPage={pageSize}
              onChangeRowsPerPage={(evt) => {
                setPageSize(parseInt(evt.target.value, 10));
                gotoPage(0);
              }}
              labelDisplayedRows={({ from, to, count }) => (
                <>{`${pageIndex + 1} of ${pageCount} Pages`}</>
              )}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 450,
    minHeight: 450,
  },
}));

export default InvoiceSearchTable;
