import * as actionTypes from "actions";

const initialState = {
  suppliers: [],
  activeSuppliers: [],
  disabledSuppliers: [],
  marketPlaces: [],
  selectedMarketPlace: "select",
  supplierProducts: [],
  supplierInvoices: [],
  selectedSupplier: {},
  selectedSupplierProduct: {},
  invoiceGenerator: {},
  newSupplierReference: {
    // _id: "6013b21410b1670110aa3ef7",
    // supplierName: "dummySupplier21",
  },
};

const supplierReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_NEW_SUPPLIER_REFERENCE: {
      return Object.assign({}, state, {
        newSupplierReference: action.payload,
      });
    }

    case actionTypes.GET_ALL_SUPPLIERS: {
      return Object.assign({}, state, {
        suppliers: action.payload,
        disabledSuppliers: [],
      });
    }

    case actionTypes.GET_ACTIVE_SUPPLIERS: {
      return Object.assign({}, state, {
        activeSuppliers: action.payload,
      });
    }

    case actionTypes.REMOVE_ACTIVE_SUPPLIERS: {
      return Object.assign({}, state, {
        activeSuppliers: [],
      });
    }

    case actionTypes.GET_ALL_MARKETPLACES: {
      return Object.assign({}, state, {
        marketPlaces: action.payload,
      });
    }

    case actionTypes.SELECT_MARKETPLACE: {
      return Object.assign({}, state, {
        selectedMarketPlace: action.payload,
      });
    }

    case actionTypes.GET_SUPPLIER_PRODUCTS: {
      return Object.assign({}, state, {
        supplierProducts: action.payload,
      });
    }

    case actionTypes.GET_SUPPLIER_INVOICES: {
      return Object.assign({}, state, {
        supplierInvoices: action.payload.map((el) => ({
          ...el,
        })),
      });
    }

    case actionTypes.SELECT_SUPPLIER: {
      return Object.assign({}, state, {
        selectedSupplier: action.payload,
      });
    }

    case actionTypes.SELECT_SUPPLIER_PRODUCT: {
      return Object.assign({}, state, {
        selectedSupplierProduct: action.payload,
      });
    }

    case actionTypes.GET_INVOICE_GENERATOR: {
      return Object.assign({}, state, {
        invoiceGenerator: action.payload,
      });
    }

    default: {
      return state;
    }
  }
};

export default supplierReducer;
