import React from "react";

import { useKeycloak } from "@react-keycloak/web";

export default ({ roles, children }) => {
  const [keycloak, initialized] = useKeycloak();

  const isAllowed = (realmRoles, pageRoles) => {
    let matchingRoles = pageRoles.filter(function (e) {
      return realmRoles.indexOf(e) > -1;
    });
    return matchingRoles.length >= 1 ? true : false;
  };

  return isAllowed(keycloak.realmAccess.roles, roles) ? children : null;
};
