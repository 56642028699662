import React from "react";
import { Button, Typography } from "@material-ui/core";

function AsinButton({ asin, ...rest }) {
  if (!asin) {
    return <></>;
  }

  return (
    <Button
      variant="outlined"
      onClick={() => {
        window.open(`https://www.amazon.com/dp/${asin}`);
      }}
      {...rest}
    >
      <Typography>{asin}</Typography>
    </Button>
  );
}

export default AsinButton;
