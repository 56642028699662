import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";

class Preview extends Component {
  render() {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <Typography variant="h4" align="center" color="secondary">
          No Preview!
        </Typography>
      </Grid>
    );
  }
}

export default Preview;
