const axios = require("axios");
const { jsonToQueryString } = require("../utils/jsonToQueryString");
const keycloak = require("../keycloak");

const baseURL = process.env.REACT_APP_BASEURL;
const baseURLV2 = process.env.REACT_APP_BASEURL_V2;

const { INVOICE_BOX, INVOICE_RANDOM } = require("../utils/values");

export function getInvoiceItems(
  filter = {},
  page = 1,
  pageSize = 10,
  sort = ["_id"]
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURLV2}invoiceitems`, {
    headers: headers,
    params: {
      ...filter,
      page,
      pageSize,
      sort,
    },
  });
}

export function getApplicationList(
  filter = {},
  page = 1,
  pageSize = 10,
  sort = ["_id"]
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  console.log("FILTERS", filter);

  return axios.get(`${baseURLV2}invoices/applications`, {
    headers: headers,
    params: {
      ...filter,
      page,
      pageSize,
      sort,
    },
  });
}

export function getInvoiceItemById(invoiceItemId) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURLV2}invoiceitems/${invoiceItemId}`, {
    headers: headers,
  });
}

export function getInvoiceItemDocsById(invoiceItemId) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURLV2}invoiceitems/${invoiceItemId}/docs`, {
    headers: headers,
  });
}

export function updateInvoiceItemById(invoiceItemId, invoiceData) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.put(`${baseURLV2}invoiceitems/${invoiceItemId}`, invoiceData, {
    headers: headers,
  });
}
