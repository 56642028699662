import React, { useState, useEffect } from "react";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import jsPDF from "jspdf";
import "jspdf-autotable";

import * as serviceCaller from "../../service";

function ViewInvoiceButton({ invoiceId, supplierId, marketPlaceId, ...rest }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  let [loading, setLoading] = useState(false);
  let [error, setError] = useState(false);

  useEffect(() => {
    if (!invoiceId || !supplierId || !marketPlaceId) {
      setError(true);
    }
  });

  return (
    <Button
      variant="outlined"
      startIcon={
        loading ? (
          <CircularProgress size={18} />
        ) : (
          <Visibility fontSize="small" />
        )
      }
      onClick={async () => {
        setLoading(true);

        try {
          let invoice = await serviceCaller.getInvoiceById(invoiceId);
          let invoiceGenerator =
            await serviceCaller.getInvoiceGeneratorBySupplierIdAndMarketplaceId(
              supplierId,
              marketPlaceId
            );

          const doc = new jsPDF("p", "mm", "letter");

          const invoiceItemSize = Number(
            invoiceGenerator.data.result.invoiceGenerator.invoiceItemsSize
          );

          function numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }

          invoice.data.result.invoiceItems =
            invoice.data.result.invoiceItems.map((e, i) => ({
              ...e,
              no: i + 1,
            }));

          const invoiceData = [
            {
              address1: "",
              address2: "",
              address3: "",
              address4: "",
              address5: "",
              address6: "",
              ...invoice.data.result.customerData,
            },
            {
              ctId: "",
              term: "",
              termNo: "",
              trackingNo: "",
              poNo: "",
              packageNo: "",
              invoiceNo: "",
              invoiceDate: "",
              salesRep: "",
              ...invoice.data.result.invoiceData,
            },
            [
              ...invoice.data.result.invoiceItems,
              ...Array.from({
                length:
                  invoiceItemSize - invoice.data.result.invoiceItems.length + 1,
              }).fill({
                no: "",
                itemCode: "",
                description: "",
                quantity: "",
                price: "",
                total: "",
              }),
            ],
            invoice.data.result.invoiceFigure,
          ];

          //Invoice preview function using JsPDF function
          const invoicePreviewFunc = eval(
            invoiceGenerator.data.result.invoiceGenerator.function
          );
          let invoiceBlobUrl = invoicePreviewFunc(
            invoiceData,
            doc,
            numberWithCommas
          );

          window.open(
            `${window.location.origin}/doc/${encodeURIComponent(
              invoiceBlobUrl
            )}?wufoo=${invoice.data.result.wufoo}&invoiceNo=${
              invoice.data.result.invoiceData.invoiceNo
            }&category=&usedCount=`
          );
        } catch (err) {
          enqueueSnackbar("Something went wrong!" + JSON.stringify(err), {
            variant: "error",
          });
        }

        setLoading(false);
      }}
      disabled={loading || error}
      {...rest}
    >
      Invoice
    </Button>
  );
}

export default ViewInvoiceButton;
