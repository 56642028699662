import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import { CircularProgress, IconButton } from "@material-ui/core";
import { useMutation } from "react-query";
import axios from "axios";
import { useSnackbar } from "notistack";

import * as serviceCaller from "../../service";

function DeleteInvoiceButton({ invoiceId }) {
  let { enqueueSnackbar, closeSnackbar } = useSnackbar();

  let mutation = useMutation(
    (invoiceId) => serviceCaller.deleteNewInvoice(invoiceId),
    {
      onError: (error, variables, context) => {
        enqueueSnackbar(
          error.response && error.response.data && error.response.data.message,
          {
            variant: "error",
          }
        );
      },
      onSuccess: (data, variables, context) => {
        enqueueSnackbar(data.data.message, {
          variant: "success",
        });
      },
    }
  );

  return (
    <>
      <IconButton
        aria-label="delete"
        onClick={() => {
          mutation.mutate(invoiceId);
        }}
        size="small"
      >
        {mutation.isLoading ? (
          <CircularProgress size={18} />
        ) : (
          <DeleteIcon variant="outlined" color="error" size="small" />
        )}
      </IconButton>
    </>
  );
}
export default DeleteInvoiceButton;
