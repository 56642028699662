const axios = require("axios");
const keycloak = require("../keycloak");

// const baseURL = "https://inv.thefunnelguru.com/api/v1/";
// const baseURL = "https://invtest.thefunnelguru.com/api/v1/";
// const baseURL = "http://localhost:3003/api/v1/";
const baseURL = process.env.REACT_APP_BASEURL;

export function addNewMasterProduct(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .post(`${baseURL}master`, data, { headers: headers })
    .then((res) => res)
    .catch(function (error) {});
}

export function disableMaster(data, callback) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .get(
      `${baseURL}master/disable?productId=${data.productId}&disabledNote=${data.disabledNote}`,
      {
        headers: headers,
      }
    )
    .then((res) =>
      res.data.statusCode == 200 ? callback(res.data.data) : null
    )
    .catch(function (error) {});
}

export function editMasterProduct(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  const productId = data.productId;
  delete data.productId;
  return axios
    .put(`${baseURL}master?productId=${productId}`, data, { headers: headers })
    .then((res) => res)
    .catch(function (error) {});
}

export function editMasterCount(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .put(
      `${baseURL}master/count?productId=${data.productId}&count=${data.count}`,
      data,
      {
        headers: headers,
      }
    )
    .then((res) => res)
    .catch(function (error) {});
}

export function productSync(supplierId) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.post(
    `${baseURL}master/productsync`,
    {
      supplierId,
    },
    {
      headers: headers,
    }
  );
}

export function productRelease(asin) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.post(
    `${baseURL}master/release`,
    {
      asin,
    },
    {
      headers: headers,
    }
  );
}

export function editMasterDummy(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .put(
      `${baseURL}master/dummy?productId=${data.productId}&dummy=${data.dummy}`,
      data,
      {
        headers: headers,
      }
    )
    .then((res) => res)
    .catch(function (error) {});
}

export function getMasterProduct(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .get(`${baseURL}master?asin=${data.asin}`, {
      headers: headers,
    })
    .then((res) => res.data.data)
    .catch(function (error) {});
}

export function getAllMasterProducts(callback) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .get(`${baseURL}master/all`, {
      headers: headers,
    })
    .then((res) =>
      res.data.statusCode === 200 ? callback(res.data.data) : callback([])
    )
    .catch(function (error) {});
}

export function searchMasterProduct(data, callback) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .get(
      `${baseURL}master/search?searchTerm=${data}&searchBy=asin&pageNo=&pageSize=`,
      {
        headers: headers,
      }
    )
    .then((res) => (res.status == 200 ? callback(res.data.data) : callback([])))
    .catch(function (error) {});
}

export function asinAvailableOn(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return (
    axios
      .get(
        `${baseURL}master/withsupplier?asin=${data.asin}&category=${data.category}&marketPlace=${data.marketPlace}`,
        {
          headers: headers,
        }
      )
      // .then((res) => (res.status == 200 ? callback(res.data.data) : callback([])))
      .then((res) => res.data)
      .catch(function (error) {})
  );
}

export function addToSupplier(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .post(`${baseURL}master/addtosupplier`, data, {
      headers: headers,
    })
    .then((res) => res.data)
    .catch(function (error) {});
}

export function getCategories(callback) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .get(`${baseURL}categories`, {
      headers: headers,
    })
    .then((res) => (res.status == 200 ? callback(res.data.data) : callback([])))
    .catch(function (error) {});
}

export function addCategory(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .post(`${baseURL}categories`, data, {
      headers: headers,
    })
    .then((res) => res)
    .catch(function (error) {});
}

export function getCoaList(callback) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .get(`${baseURL}datalist/coaList`, {
      headers: headers,
    })
    .then((res) => (res.status == 200 ? callback(res.data.data) : callback([])))
    .catch(function (error) {});
}

export function uploadImageSet(data, fileUploadPercentage) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  const formData = new FormData();

  data.images.forEach((image, index) => {
    formData.append("images", image);
  });

  return axios
    .post(`${baseURL}fileupload/imageset?asin=${data.asin}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        fileUploadPercentage(percentCompleted);
      },
    })
    .then((res) => res)
    .catch(function (error) {});
}

export function uploadFile(data, fileUploadPercentage) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  const formData = new FormData();

  formData.append("file", data.file);

  return axios
    .post(
      `${baseURL}fileupload?fileType=${data.fileType}&qmsgmpDate=${data.qmsgmpDate}&masterId=${data.masterId}&coaTemplate=${data.coaTemplate}&fileDate=${data.fileDate}&coaDate=${data.coaDate}&fdaDate=${data.fdaDate}&marketPlaceId=${data.marketPlaceId}`,
      formData,
      {
        headers: headers,
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          fileUploadPercentage(percentCompleted);
        },
      }
    )
    .then((res) => res)
    .catch(function (error) {});
}

export function getFiles(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .get(
      `${baseURL}fileupload?fileType=${data.fileType}&masterId=${data.masterId}&asin=${data.asin}`,
      {
        headers: headers,
      }
    )
    .then((res) => res.data.data)
    .catch(function (error) {});
}
export function uploadCoa(data, fileUploadPercentage) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  const formData = new FormData();

  formData.append("coa", data.coa);

  return axios
    .post(
      `${baseURL}fileupload/coa?asin=${data.asin}&coaDate=${data.coaDate}`,
      formData,
      {
        headers: headers,
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          fileUploadPercentage(percentCompleted);
        },
      }
    )
    .then((res) => res)
    .catch(function (error) {});
}

export function uploadFda(data, fileUploadPercentage) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  const formData = new FormData();

  formData.append("fda", data.fda);

  return axios
    .post(
      `${baseURL}fileupload/fda?asin=${data.asin}&fdaDate=${data.fdaDate}`,
      formData,
      {
        headers: headers,
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          fileUploadPercentage(percentCompleted);
        },
      }
    )
    .then((res) => res)
    .catch(function (error) {});
}

export function getImageSets(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .get(`${baseURL}fileupload/imageset?asin=${data.asin}`, {
      headers: headers,
    })
    .then((res) => res.data.data)
    .catch(function (error) {});
}

export function getCoaDocsList(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .get(`${baseURL}fileupload/coa?asin=${data.asin}`, {
      headers: headers,
    })
    .then((res) => res.data.data)
    .catch(function (error) {});
}

export function getFdaDocsList(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .get(`${baseURL}fileupload/fda?asin=${data.asin}`, {
      headers: headers,
    })
    .then((res) => res.data.data)
    .catch(function (error) {});
}

export function getSignedUrl(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .get(
      `${baseURL}fileupload/signedUrl?fileType=${data.fileType}&fileName=${data.fileName}&asin=${data.asin}`,
      {
        headers: headers,
      }
    )
    .then((res) => res.data.data)
    .catch(function (error) {});
}

export function getObject(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .get(`${baseURL}fileupload/object?fileId=${data.fileId}`, {
      headers: headers,
    })
    .then((res) => res.data.data)
    .catch(function (error) {});
}
