const axios = require("axios");
const keycloak = require("../keycloak");

// const baseURL = "https://inv.thefunnelguru.com/api/v1/";
// const baseURL = "https://invtest.thefunnelguru.com/api/v1/";
// const baseURL = "http://localhost:3003/api/v1/";
const baseURL = process.env.REACT_APP_BASEURL;

export function getUserData(data, callback) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .get(`${baseURL}users?kc_id=${data}`, {
      headers: headers,
    })
    .then((res) => {
      return res.data.statusCode == 200 ? callback(res.data.user) : null;
    })
    .catch(function (error) {});
}
