import React from "react";
import { Button } from "@material-ui/core";

import { useSnackbar } from "notistack";
import * as serviceCaller from "../../service";

function NotUsedInvoiceItemAction({
  invoiceData,
  invoiceItemData,
  setInvoiceItemData,
}) {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Button
      size="small"
      onClick={async () => {
        serviceCaller
          .updateInvoiceItemById(invoiceItemData._id, {
            result: "notUsed",
          })
          .then((res) => {
            if (res.status === 200) {
              enqueueSnackbar(res.data.message, {
                variant: "success",
              });
              setInvoiceItemData({
                ...invoiceItemData,
                result: res.data.result.result,
              });
            } else {
              enqueueSnackbar(res.data.message, {
                variant: "error",
              });
            }
          });
      }}
    >
      NOT USED
    </Button>
  );
}

export default NotUsedInvoiceItemAction;
