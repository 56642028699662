const axios = require("axios");
const keycloak = require("../keycloak");

// const baseURL = "https://inv.thefunnelguru.com/api/v1/";
// const baseURL = "https://invtest.thefunnelguru.com/api/v1/";
// const baseURL = "http://localhost:3003/api/v1/";
const baseURL = process.env.REACT_APP_BASEURL;

export function updateSettings(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .put(`${baseURL}settings`, data, { headers: headers })
    .then((res) => res)
    .catch(function (error) {});
}

export function getSettings(callback) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .get(`${baseURL}settings`, {
      headers: headers,
    })
    .then((res) =>
      res.data.statusCode == 200 ? callback(res.data.data[0]) : null
    )
    .catch(function (error) {});
}
