//INVOICE TYPES
export const INVOICE_GENERAL = "INVOICE_GENERAL";
export const INVOICE_BOX = "INVOICE_BOX";
export const INVOICE_RANDOM = "INVOICE_RANDOM";
export const INVOICE_AUTOMOTIVE = "INVOICE_AUTOMOTIVE";

//INVOICE STATUS
export const INVOICE_OPEN = "OPEN";
export const INVOICE_VALIDATED = "VALIDATED";
export const INVOICE_REVALIDATED = "REVALIDATED";
export const INVOICE_CLOSED = "CLOSED";

//MANUFACTURED_BY TYPES
export const NOT_APPLICABLE = "NOT_APPLICABLE";
export const DISTRIBUTED_BY = "DISTRIBUTED_BY";
export const MANUFACTURED_BY = "MANUFACTURED_BY";
export const DIST_AND_MANF_BY = "DIST_AND_MANF_BY";
export const CREATED_BY = "CREATED_BY";
export const IMPORTED_BY = "IMPORTED_BY";
export const RANDOM_BOX = "RANDOM_BOX";
export const MANUFACTURED_BOX = "MANUFACTURED_BOX";

//ROLES
export const INV_V2_ADMIN = "inv-v2-admin";
export const INV_V2_CASELOG = "inv-v2-caselog";
export const INV_V2_DOCUMENTATION = "inv-v2-documentation";
export const INV_V2_VALIDATION = "inv-v2-validation";
export const APPROVED = "approved";
export const REJECTED = "rejected";
export const PRIMARY = "Primary";
export const SECONDARY = "Secondary";
export const DUMMY = "Dummy";
export const BLANK = "Blank";
