const axios = require("axios");
const { jsonToQueryString } = require("../utils/jsonToQueryString");
const keycloak = require("../keycloak");

const baseURL = process.env.REACT_APP_BASEURL;
const baseURLV2 = process.env.REACT_APP_BASEURL_V2;

export function getRejectedList(
  filter = {},
  page = 1,
  pageSize = 10,
  sort = ["_id"]
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURLV2}rejectedlists`, {
    headers: headers,
    params: {
      ...filter,
      page,
      pageSize,
      sort,
    },
  });
}

export function addRejectList(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.post(`${baseURLV2}rejectedlists`, data, { headers: headers });
}
