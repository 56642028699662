import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

import { useForm, Controller } from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";
import { useSnackbar } from "notistack";
import * as serviceCaller from "../../service";
import {
  INVOICE_AUTOMOTIVE,
  INVOICE_RANDOM,
  INVOICE_BOX,
  INVOICE_GENERAL,
} from "../../utils/values";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

function ApproveInvoiceItemAction({
  invoiceData,
  invoiceItemData,
  setInvoiceItemData,
}) {
  let [approvedState, setApprovedState] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { handleSubmit, control } = useForm({
    defaultValues: {
      appliedOn: new Date().getTime(),
    },
  });

  const approvedProduct = (approvedFormData) => {
    let newData = {
      ...approvedFormData,
      appliedOn: new Date(approvedFormData.appliedOn).getTime(),
      invoice_id: invoiceData._id,
      invoiceItemId: invoiceItemData._id,
      //Below fields not available in automotive, random, box
      // master_id: invoiceItemData.masterproduct._id,
      // supplier_id: invoiceData.supplier_id,
      // marketPlace: invoiceData.marketPlaceId,
    };
    console.log("qms", newData);

    if (invoiceData.invoiceType == INVOICE_GENERAL) {
      newData.master_id = invoiceItemData.masterproduct._id;
      newData.supplier_id = invoiceData.supplier_id;
      newData.marketPlace = invoiceData.marketPlaceId;
    }

    if (invoiceData.invoiceType == INVOICE_RANDOM) {
      newData.marketPlace = invoiceData.marketPlaceId;
    }

    if (invoiceItemData.coaDoc) {
      newData.reportType = "COA";
      newData.coaType = invoiceItemData.coaDoc;
    }

    serviceCaller.addApprovedList(newData).then((res) => {
      if (res.status === 201) {
        enqueueSnackbar(res.data.message, {
          variant: "success",
        });
        setInvoiceItemData({
          ...invoiceItemData,
          result: res.data.result.invoiceItem.result,
        });
        setApprovedState(false);
      } else {
        enqueueSnackbar(res.data.message, {
          variant: "error",
        });
      }
    });
  };

  return (
    <>
      <Button
        onClick={() => setApprovedState(true)}
        variant="contained"
        color="primary"
        size="small"
      >
        Approved
      </Button>
      <Dialog onClose={() => setApprovedState(false)} open={approvedState}>
        <DialogTitle>{"PRODUCT APPROVED"}</DialogTitle>
        <form onSubmit={handleSubmit(approvedProduct)}>
          <DialogContent>
            <Grid alignItems="center" container justify="center" spacing={2}>
              <Grid item xs={6}>
                <Controller
                  render={({ onChange, value }) => (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Approved On"
                        value={value}
                        onChange={onChange}
                        format="MM/dd/yyyy"
                        fullWidth
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  )}
                  name="appliedOn"
                  rules={{ required: true }}
                  control={control}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      label="Approved By"
                      variant="outlined"
                      type="text"
                    />
                  }
                  name="approvedBy"
                  rules={{ required: true }}
                  control={control}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  as={
                    <Select
                      fullWidth
                      label="Select Report Type"
                      variant="outlined"
                      defaultValue="NA"
                    >
                      <MenuItem value="COA">COA</MenuItem>
                      <MenuItem value="FDA">FDA</MenuItem>
                      <MenuItem value="GMP">GMP</MenuItem>
                      <MenuItem value="QMS">QMS</MenuItem>
                      <MenuItem value="NA">Not Applicable</MenuItem>
                    </Select>
                  }
                  name="reportType"
                  control={control}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      label="Document Type"
                      variant="outlined"
                      type="text"
                    />
                  }
                  name="docType"
                  rules={{ required: true }}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      multiline
                      rows="4"
                      type="text"
                      variant="outlined"
                      label="Remarks"
                    />
                  }
                  name="remarks"
                  rules={{ required: true }}
                  control={control}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setApprovedState(false)}>
              Cancel
            </Button>
            <Button color="primary" type="submit" variant="contained">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default ApproveInvoiceItemAction;
