const axios = require("axios");
const { jsonToQueryString } = require("../utils/jsonToQueryString");
const keycloak = require("../keycloak");

// const baseURL = "https://inv.thefunnelguru.com/api/v1/";
// const baseURL = "https://invtest.thefunnelguru.com/api/v1/";
// const baseURL = "http://localhost:3003/api/v1/";
const baseURL = process.env.REACT_APP_BASEURL;
const baseURLV2 = process.env.REACT_APP_BASEURL_V2;
const baseURL2 = process.env.REACT_APP_BASEURL_V2;

const { INVOICE_BOX, INVOICE_RANDOM } = require("../utils/values");

export function getInvoices(
  filter = {},
  page = 1,
  pageSize = 10,
  sort = ["-_id"]
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURLV2}invoices`, {
    headers: headers,
    params: {
      ...filter,
      page,
      pageSize,
      sort,
    },
  });
}
export function getOtherInvoices(
  filter = {},
  page = 1,
  pageSize = 10,
  sort = ["-_id"]
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURLV2}invoices/otherinvoices`, {
    headers: headers,
    params: {
      ...filter,
      page,
      pageSize,
      sort,
    },
  });
}

export function getInvoicesBySupplier(
  supplierId,
  filter = {},
  page = 1,
  pageSize = 10,
  sort = ["-_id"]
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURLV2}suppliers/${supplierId}/invoices`, {
    headers: headers,
    params: {
      ...filter,
      page,
      pageSize,
      sort,
    },
  });
}

export function addInvoiceV2(invoiceData) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.post(`${baseURLV2}invoices`, invoiceData, {
    headers: headers,
  });
}

export function getInvoiceById(invoiceId) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURLV2}invoices/${invoiceId}`, {
    headers: headers,
  });
}

export function updateInvoiceStatus(invoiceId, { userId, invoiceStatus }) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.patch(
    `${baseURLV2}invoices/${invoiceId}`,
    {
      invoiceStatus,
      userId,
    },
    {
      headers: headers,
    }
  );
}

export function getInvoiceItemsByInvoiceId(invoiceId) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURLV2}invoices/${invoiceId}/invoiceitems`, {
    headers: headers,
  });
}

export function getCaseDetailByInvoiceId(invoiceId) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURLV2}invoices/${invoiceId}/casedetails`, {
    headers: headers,
  });
}

export function updateCaseDetailByInvoiceId(invoiceId, caseDetailData) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.put(
    `${baseURLV2}invoices/${invoiceId}/casedetails`,
    caseDetailData,
    {
      headers: headers,
    }
  );
}

export function getInvoiceType(
  filter = {},
  page = 1,
  pageSize = 10,
  sort = ["-_id"]
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURLV2}invoices/otherinvoices`, {
    headers: headers,
    params: {
      ...filter,
      page,
      pageSize,
      sort,
    },
  });
}

export function addInvoice(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  let formData = new FormData();
  for (let key of Object.keys(data)) {
    formData.append(key, JSON.stringify(data[key]));
  }

  if (data.invoiceType == INVOICE_BOX || data.invoiceType == INVOICE_RANDOM) {
    formData.append("file", data.file);
  }
  // let datas = data.invoiceType;

  return (
    axios
      // .post(`${baseURL}invoice?page=1&limit=10&invoiceType=${datas}`, formData, {
      // .post(`https://invtest.thefunnelguru.com/api/v1/invoice`, formData, {
      .post(`${baseURL}invoice`, formData, {
        headers: headers,
      })
      .then((res) => res)
      .catch(function (error) {})
  );
}

export function addOtherInvoice(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  // let formData = new FormData();
  // for (let key of Object.keys(data)) {
  //   formData.append(key, JSON.stringify(data[key]));
  // }

  // if (data.invoiceType == INVOICE_BOX || data.invoiceType == INVOICE_RANDOM) {
  //   formData.append("file", data.file);
  // }
  // let datas = data.invoiceType;

  return (
    axios
      // .post(`${baseURL}invoice?page=1&limit=10&invoiceType=${datas}`, formData, {
      // .post(`https://invtest.thefunnelguru.com/api/v1/invoice`, formData, {
      .post(`${baseURL2}invoices`, data, {
        headers: headers,
      })
      .then((res) => res)
      .catch(function (error) {})
  );
}

export function predictInvoice(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .get(`${baseURL}invoice/predict?category=${data.category}`, {
      headers: headers,
    })
    .then((res) => res)
    .catch(function (error) {});
}

export function editInvoice(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  const invoiceId = data._id;
  delete data._id;

  let formData = new FormData();
  for (let key of Object.keys(data)) {
    formData.append(key, JSON.stringify(data[key]));
  }

  if (data.invoiceType == INVOICE_BOX || data.invoiceType == INVOICE_RANDOM) {
    formData.append("file", data.file);
  }

  // const invoiceId = data.invoiceId;
  // delete data.invoiceId;
  return axios
    .put(`${baseURL}invoice?invoiceId=${invoiceId}`, formData, {
      headers: headers,
    })
    .then((res) => res)
    .catch(function (error) {});
}

export function editOtherInvoice(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  // const invoiceId = data._id;
  // delete data._id;

  // let formData = new FormData();
  // for (let key of Object.keys(data)) {
  //   formData.append(key, JSON.stringify(data[key]));
  // }

  // if (data.invoiceType == INVOICE_BOX || data.invoiceType == INVOICE_RANDOM) {
  //   formData.append("file", data.file);
  // }

  // const invoiceId = data.invoiceId;
  // delete data.invoiceId;
  return axios.put(`${baseURL2}invoices/${data._id}`, data, {
    headers: headers,
  });
}

export function editWufoo(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  const wufoo = data.wufoo;
  // delete data.wufoo;
  return axios
    .put(`${baseURL}invoice/wufoo?wufoo=${wufoo}`, data, {
      headers: headers,
    })
    .then((res) => res)
    .catch(function (error) {});
}

export function editApplication(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  const invoiceId = data.invoiceId;
  const asin = data.asin;
  delete data.invoiceId;
  delete data.asin;

  return axios
    .put(
      `${baseURL}invoice/application?invoiceId=${invoiceId}&asin=${asin}`,
      data,
      {
        headers: headers,
      }
    )
    .then((res) => res)
    .catch(function (error) {});
}

export function validateInvoice(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .get(
      `${baseURL}invoice/${data.invoiceId}/validated?validatorId=${data.validatorId}`,
      {
        headers: headers,
      }
    )
    .then((res) => res)
    .catch(function (error) {});
}

export function closeInvoice(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .get(
      `${baseURL}invoice/${data.invoiceId}/closed?caseAppliedById=${data.caseAppliedById}`,
      {
        headers: headers,
      }
    )
    .then((res) => res)
    .catch(function (error) {});
}

export function reopenInvoice(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .get(
      `${baseURL}invoice/${data.invoiceId}/reopen?caseAppliedById=${data.caseAppliedById}`,
      {
        headers: headers,
      }
    )
    .then((res) => res)
    .catch(function (error) {});
}

export function sendApproved(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .post(`${baseURL}datalist/approved`, data, {
      headers: headers,
    })
    .then((res) => res)
    .catch(function (error) {});
}

export function sendProductNotUsed(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .post(`${baseURL}datalist/notused`, data, {
      headers: headers,
    })
    .then((res) => res)
    .catch(function (error) {});
}

export function sendProductRollBack(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .post(`${baseURL}datalist/rollback`, data, {
      headers: headers,
    })
    .then((res) => res)
    .catch(function (error) {});
}

export function sendRejected(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .post(`${baseURL}datalist/rejected`, data, {
      headers: headers,
    })
    .then((res) => res)
    .catch(function (error) {});
}

export function getAllInvoices(data, callback) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .get(
      `${baseURL}invoice${jsonToQueryString(data)}`,

      {
        headers: headers,
      }
    )
    .then((res) => {
      if (callback) {
        return res.data.statusCode === 200
          ? callback(res.data.data.docs)
          : callback([]);
      } else {
        return res.data;
      }
    })
    .catch(function (error) {});
}

export function getReports(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .get(
      `${baseURL}datalist/?listType=${data.listType}&startDate=${data.startDate}&endDate=${data.endDate}&skip=${data.skip}&page=${data.page}`,

      // `${baseURL}datalist/?listType=${data.listType}&asinSearch=${data.asinSearch}&nameSearch=${data.nameSearch}&wufooSearch=${data.wufooSearch}&reportType=${data.reportType}&coaTypes=${data.coaTypes}&startDate=${data.startDate}&endDate=${data.endDate}&categories=${data.categories}&suppliers=${data.suppliers}&skip=${data.skip}&page=${data.page}&sort=${data.sort}`,
      {
        headers: headers,
      }
    )
    .then((res) => res)
    .catch(function (error) {});
}

export function getSupportingDoc(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .get(
      `${baseURL}invoice/supportdoc?invoiceId=${data.invoiceId}&asin=${data.asin}`,
      {
        headers: headers,
      }
    )
    .then((res) => res)
    .catch(function (error) {});
}

export function deleteInvoice(id) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios
    .delete(`${baseURL}invoice/?invoiceId=${id}`, {
      headers: headers,
    })
    .then((res) => res.data)
    .catch(function (error) {});
}

// Add New Invoice

export function addNewInvoice(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.post(`${baseURL2}invoices`, data, {
    headers: headers,
  });
}

export function updateNewInvoice(invoiceId, invoiceData) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.put(`${baseURL2}invoices/${invoiceId}`, invoiceData, {
    headers: headers,
  });
}

export function deleteNewInvoice(invoiceId, invoiceData) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.delete(`${baseURL2}invoices/${invoiceId}`, {
    headers: headers,
  });
}
